<template>
  <div id="natcon-member-payment-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>
          {{ PageTitle }}
        </h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>Regular subscription can be paid only once.</li>
            <li>
              After paying regular subscription, choose additional subcription.
            </li>
            <li>
              Late fee is optional but paying late fee is required to get voting
              rights.
            </li>
            <li>
              After completing the payment, kindly select your members for whom
              the payment was made.
            </li>
          </ol>
        </v-container>
        <h6>* indicates required field</h6>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-row wrap>
            <v-col align="center" cols="12" sm="12" md="12">
              <v-btn
                @click.prevent="refreshPageData"
                color="#4285f4"
                elevation="30"
                shaped
                tile
                small
                class="btn btn-primary font-size-h6 px-6 py-4 my-2 mr-2 white--text"
              >
                Refresh
              </v-btn>
              <v-btn
                @click.prevent="resetForm"
                color="#f25022"
                elevation="30"
                shaped
                tile
                small
                class="btn btn-primary font-size-h6 px-6 py-4 my-2 mr-2 white--text"
              >
                Clear
              </v-btn>
            </v-col>
          </v-row>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-card elevation="3" outlined>
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                >Registration Details</v-card-title
              >
              <v-container>
                <v-row wrap>
                  <v-col cols="12" md="3">
                    <h4 class="text-primary">
                      <span class="text-danger">*</span> Member Type
                    </h4>
                    <v-select
                      v-model="MemberType"
                      :items="MemberTypeOptions"
                      :rules="MemberTypeRules"
                      :reduce="(option) => option.value"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4" v-if="MemberType == 2">
                    <h4 class="text-primary">
                      <span class="text-danger">*</span> Member
                    </h4>
                    <v-autocomplete
                      @change="getSelectedMemberDetails"
                      :reduce="(option) => option.value"
                      :loading="MemberIdOptionsLoading"
                      :items="MemberIdOptions"
                      :rules="MemberIdRules"
                      v-model="MemberId"
                      required
                      outlined
                      dense
                      clearable
                    >
                      <template v-slot:selection="{ item }">
                        <img width="50" height="50" :src="item.photo" />{{
                          item.text
                        }}
                      </template>
                      <template v-slot:item="{ item }">
                        <img width="50" height="50" :src="item.photo" />{{
                          item.text
                        }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />

            <v-card elevation="3" outlined v-if="MemberType != ''">
              <v-card-title class="mycard-title-1 fsize-4 fcolor-1 bg-color-2"
                >Member Details</v-card-title
              >
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <h4 class="text-primary">Member photo</h4>
                    <v-img
                      :src="selectedMember.MemberImagePath"
                      :alt="selectedMember.MemberName"
                      lazy-src="/no-image-available.png"
                      max-width="100"
                      max-height="100"
                      v-if="selectedMember.MemberImagePath != ''"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h4 class="text-primary">
                        <span class="text-danger">*</span> Member Details
                      </h4>
                    </label>
                    <h3>{{ selectedMember.MemberName }}</h3>
                    <h3>{{ selectedMember.MembershipId }}</h3>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h4 class="text-primary">
                        <span class="text-danger">*</span> Email
                      </h4>
                    </label>
                    <v-text-field
                      v-model="selectedMember.EmailId"
                      :rules="EmailIdRules"
                      hint="Enter the Email"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h4 class="text-primary">
                        <span class="text-danger">*</span> Phone No
                      </h4>
                    </label>
                    <v-text-field
                      v-model="selectedMember.MobileNo"
                      :rules="MobileNoRules"
                      :counter="10"
                      v-mask="'##########'"
                      hint="Enter the Phone No"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <br />

            <v-dialog v-model="PreviewPlanFlag" width="500">
              <event-plan-details
                :Plan="PreviewPlanDetails"
                @hideDialog="hidePreviewPlanPopup($event)"
                v-if="PreviewPlanFlag"
              ></event-plan-details>
            </v-dialog>

            <v-row justify="center" class="ma-3">
              <div v-for="(item, index) in Plans" :key="index">
                <v-col cols="12" sm="4" md="4" lg="4">
                  <div v-if="item.Recommended">
                    <div class="cards-box" v-if="item.PlanVisibleFlag">
                      <div class="cards popular">
                        <div class="cards-ribbon">
                          <span>most popular</span>
                        </div>
                        <div class="cards-title-bar">
                          <h3 class="cards-title">{{ item.PlanName }}</h3>
                        </div>
                        <div class="cards-price">
                          <h4 class="cards-price-amount">
                            {{ item.TotalAmountTxt }}
                            <small class="cards-price-validity">per head</small>
                          </h4>
                          <v-divider />
                          <v-btn
                            color="primary"
                            dark
                            @click.prevent="showPreviewPlanPopup(item)"
                            >Show Plan details</v-btn
                          >
                          <p></p>
                        </div>
                        <br />
                        <div class="cards-action">
                          <v-divider />
                          <button
                            class="cards-action-button"
                            :disabled="!valid1"
                            @click.prevent="confirmAlert(item)"
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="cards-box" v-if="item.PlanVisibleFlag">
                      <div class="cards">
                        <div class="cards-title-bar">
                          <h3 class="cards-title">{{ item.PlanName }}</h3>
                          <h4 class="cards-subtitle">
                            {{ item.PlanDescription }}.
                          </h4>
                        </div>
                        <div class="cards-price">
                          <h4 class="cards-price-amount">
                            {{ item.TotalAmountTxt }}
                            <small class="cards-price-validity">per head</small>
                          </h4>
                          <v-divider />
                          <v-btn
                            color="primary"
                            dark
                            @click.prevent="showPreviewPlanPopup(item)"
                            >Show Plan details</v-btn
                          >
                          <p></p>
                        </div>
                        <br />
                        <div class="cards-action">
                          <v-divider />
                          <button
                            class="cards-action-button"
                            :disabled="!valid1"
                            @click.prevent="confirmAlert(item)"
                            :loading="SubmitFlag"
                          >
                            Pay now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EventPlanDetails from "@/view/pages/erp/events/natcon/EventPlanDetails.vue";

export default {
  mixins: [common],
  components: {
    EventPlanDetails,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      NotesFlag: false,
      rows: [],

      PreviewPlanFlag: false,
      PreviewPlanDetails: {},

      tableColumns1: [],
      tableColumns2: [
        { text: "Date", value: "PaidDateTxt", width: "13%" },
        { text: "Description", value: "Description", width: "35%" },
        { text: "Late Fee", value: "FineAmountTxt", width: "13%" },
        { text: "To be paid", value: "FineNotPaid", width: "13%" },
        { text: "No of members", value: "Qty", width: "13%" },
        { text: "Sub Total", value: "SubTotal", width: "13%" },
      ],

      selected: [],
      search: "",

      RegistrationTypeId: 3,

      MemberType: "",
      MemberTypeRules: [(v) => !!v || "Member Type for is required"],
      MemberTypeOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "For me" },
        { value: 2, text: "For another member" },
      ],

      MemberTypeId: 1,

      Lom: {},

      Member: {},

      Presidentmember: {},
      Plans: [],

      selectedPlan: {},
      selectedMember: {},

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      Events: {},

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberImageFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      OrderType: 1,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      GstNumberFlag: 2,
      GstNumberFlagRules: [
        (v) => !!v || "Does your LOM has GST number field required",
      ],
      GstNumberFlagOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      GstNumber: "",
      GstNumberRules: [(v) => !!v || "GST number is required"],

      ZoneCode: "",
      LomCode: "",
      MemberId: "",
      UserMemberId: "",

      EventId: "",
      EventTypeId: 9,

      Order: {
        back_url: "",
        customer_name: "",
        customer_email_id: "",
        customer_mobile_no: "",
        rzp_action: "#",
        rzp_account_id: "",
        rzp_key: "bdgzbd",
        rzp_amount: "",
        rzp_order_id: "",
        rzp_buttontext: "Pay now",
        rzp_name: "JCI India Foundation",
        rzp_description: "",
        rzp_image: "",
        rzp_color: "#0096d5",
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var UserMemberId = this.$session.get("MemberId");
        this.UserMemberId = UserMemberId;
        console.log({ UserMemberId });

        var ZoneId = this.$session.get("ZoneId");
        this.ZoneCode = ZoneId;
        console.log({ ZoneId });

        var LomId = this.$session.get("LomId");
        this.LomCode = LomId;
        console.log({ LomId });

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    MemberType: function () {
      console.log("watch MemberType");
      this.selectedMember = {};
      var MemberType = this.MemberType;
      console.log("MemberType=" + MemberType);
      if (MemberType != "") {
        if (MemberType == 1) {
          var Member = this.Member;
          console.log("Member=" + JSON.stringify(Member));
          this.selectedMember = {
            MemberId: Member.MemberId,
            MemberName: Member.Fullname,
            MembershipId: Member.MembershipId,
            MobileNo: Member.MobileNo,
            EmailId: Member.EmailId,
            MemberImagePath: Member.MemberImage,
          };
        } else if (MemberType == 2) {
          this.selectedMember = {};
          this.MemberId = "";
          this.getSelectedMemberDetails();
        } else {
        }
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var id = this.$route.query.id;
      console.log({ id });
      this.EventId = id;
      var url1 = "api/jci-program/show";
      var condition1 = {
        UserInterface: 1,
        Program: id,
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },

    pageData() {
      console.log("pageData called");
      this.getMemberDetails();
      this.getMemberIdOptions();
      this.getEventsDetails();
    },
    showPreviewPlanPopup(tr) {
      this.PreviewPlanDetails = tr;
      this.PreviewPlanFlag = true;
    },
    hidePreviewPlanPopup() {
      this.PreviewPlanDetails = {};
      this.PreviewPlanFlag = false;
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form2.reset();
      this.selectedMember = {};
      this.selectedPlan = {};
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        LomCode: this.LomCode,
        MemberType: this.MemberTypeId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getSelectedMemberDetails() {
      console.log("getSelectedMemberDetails called");
      this.selectedMember = {};
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.selectedMember = {
          MemberId: member.value,
          MemberName: member.member_name,
          MembershipId: member.membership_id,
          MobileNo: "",
          EmailId: "",
          MemberImagePath: member.photo,
        };
      }
    },
    getMemberDetails() {
      console.log("getMemberDetails is called");

      var MemberId = this.UserMemberId;
      var ZoneCode = this.ZoneCode;
      var LomCode = this.LomCode;
      console.log(
        "MemberId=" +
          MemberId +
          "; ZoneCode=" +
          ZoneCode +
          "; LomCode=" +
          LomCode
      );

      if (MemberId != "" && ZoneCode != "" && LomCode != "") {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 4,
          MemberId: MemberId,
          ZoneCode: ZoneCode,
          LomCode: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.Member = records;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (MemberId == "" || ZoneCode == "" || LomCode == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    getEventsDetails() {
      console.log("getEventsDetails is called");

      var EventCode = this.EventId;
      console.log("EventCode=" + EventCode);

      if (EventCode != "") {
        this.LoadingFlag = true;
        this.Events = {};
        this.Plans = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-program/plan/lists";
        var upload = {
          UserInterface: 2,
          Program: EventCode,
          ProgramType: this.EventTypeId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              // thisIns.Events = records;
              thisIns.Plans = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    confirmAlert(selectedPlan) {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form2.validate();
      this.selectedPlan = selectedPlan;
      var selectedMember = this.selectedMember;
      console.log({ validate1 });
      console.log({ selectedPlan });
      console.log({ selectedMember });

      var MemberId = selectedMember.MemberId;
      MemberId = MemberId == (null || undefined) ? "" : MemberId;
      console.log({ MemberId });

      var EventPlanId = selectedPlan.EventPlanId;
      EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
      console.log({ EventPlanId });

      if (validate1 && MemberId != "" && EventPlanId != "") {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<b>Selected member details</b>";
        htmlTxt += "<br/>Member Name <b>" + selectedMember.MemberName + "</b>";
        htmlTxt += "<br/>Mobile no. <b>" + selectedMember.MobileNo + "</b>";
        htmlTxt += "<br/>Email id <b>" + selectedMember.EmailId + "</b>";
        htmlTxt +=
          "<br/><img width='100' height='100' src='" +
          selectedMember.MemberImagePath +
          "' />";
        htmlTxt += "<br/><b>Selected plan details</b>";
        htmlTxt += "<br/>Plan name <b>" + selectedPlan.PlanName + "</b>";
        htmlTxt +=
          "<br/>Total amount <b>" + selectedPlan.TotalAmountTxt + "</b>";
        htmlTxt += "<br/><br/>Do you want to continue?";
        Swal.fire({
          title: "Please verify all the information before proceeding",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (MemberId == "") {
          message += "Kindly select member. ";
        }
        if (EventPlanId == "") {
          message += "Kindly select plan. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var selectedPlan = this.selectedPlan;
      var selectedMember = this.selectedMember;
      console.log({ validate1 });
      console.log({ selectedPlan });
      console.log({ selectedMember });

      var MemberId = selectedMember.MemberId;
      MemberId = MemberId == (null || undefined) ? "" : MemberId;
      console.log({ MemberId });

      var EventPlanId = selectedPlan.EventPlanId;
      EventPlanId = EventPlanId == (null || undefined) ? "" : EventPlanId;
      console.log({ EventPlanId });

      var participants = [];
      participants.push(selectedMember);

      var TotalCount = participants.length;
      console.log("TotalCount=" + TotalCount);

      var EventTotalAmount = selectedPlan.TotalAmount;
      console.log("EventTotalAmount=" + EventTotalAmount);

      var TotalAmount = parseFloat(TotalCount) * parseFloat(EventTotalAmount);
      console.log("TotalAmount=" + TotalAmount);

      if (validate1 && TotalCount > 0 && TotalAmount > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        // var add_url = server_url + "api/members/events/orders/create";
        var add_url = server_url + "api/jci-program/order/create";
        var upload = {
          UserInterface: 11,
          RegistrationType: this.RegistrationTypeId,
          Lom: this.LomCode,
          Member: MemberId,
          Event: this.EventId,
          Plan: EventPlanId,
          MobileNo: selectedMember.MobileNo,
          EmailId: selectedMember.EmailId,
          TotalCount: TotalCount,
          TotalAmount: TotalAmount,
          MemberDetails: this.Member,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.Order = records.Order;
              thisIns.makePayment();
            } else {
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalCount == 0) {
          message += "Kindly select member. ";
        }
        if (TotalAmount == 0) {
          message += "Kindly select a plan. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    makePayment() {
      console.log("makePayment");
      var order = this.Order;
      const thisIns = this;
      var rzp1 = new Razorpay({
        key: order.rzp_key,
        account_id: order.rzp_account_id,
        order_id: order.rzp_order_id,
        amount: order.rzp_amount,
        name: order.rzp_name,
        currency: "INR",
        description: order.rzp_description,
        image: order.rzp_image,
        theme: {
          color: order.rzp_color,
        },
        prefill: {
          name: order.customer_name,
          email: order.customer_email_id,
          contact: order.customer_mobile_no,
        },
        notes: {
          address: "",
        },
        redirect: false,
        handler: function (response) {
          console.log("razorpay_payment_id=" + response.razorpay_payment_id);
          thisIns.SubmitFlag = true;
          thisIns.pageRedirect({}, "/order/success");
        },
      });
      rzp1.open();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
#natcon-member-payment-create {
  @import url(https://fonts.googleapis.com/css?family=Lato:700);
  $color: #8950fc;
  $color1: #191970;
  .cards {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
    flex: 1;
    margin: 8px;
    padding: 10px;
    padding-top: 35px;
    height: 370px;
    width: 225px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
    border: solid 1px #4f1990;
  }
  .cards-box {
    height: 380px;
    margin-top: 10px;
  }
  .cards.popular {
    margin-top: -10px;
    height: 400px;
    padding: 20px;
    padding-top: 40px;
    width: 225px;
  }
  .cards-title-bar .cards-title {
    color: $color1;
    font-size: 18px;
    vertical-align: middle;
    text-transform: uppercase;
  }
  .cards.popular .cards-title-bar .cards-title {
    color: $color;
    vertical-align: middle;
    font-size: 20px;
  }
  .cards-price .cards-price-amount {
    color: $color1;
    font-size: 30px;
  }
  .cards.popular .cards-price .cards-price-amount {
    color: $color;
    font-size: 32px;
  }
  .cards.popular .cards-action .cards-action-button {
    background-color: $color;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    height: 60px;
  }
  .cards.popular .cards-action .cards-action-button:hover {
    background-color: $color;
    font-size: 20px;
  }
  .cards:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  }
  .cards-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 114px;
    height: 112px;
  }
  .cards-ribbon span {
    position: absolute;
    z-index: 1;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }
  .cards-ribbon::before,
  .cards-ribbon::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .cards-ribbon::before {
    top: 0;
    right: 0;
  }
  .cards-ribbon::after {
    bottom: 0;
    left: 0;
  }
  .cards-title-bar .cards-subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
  .cards-price {
    margin: 30px 0 -28px 0;
  }
  .cards.popular .cards-price {
    margin: 30px 0 -28px 0;
  }
  .cards-price .cards-price-amount .cards-price-sypol {
    font-size: 17px;
    display: inline-block;
    margin-left: -20px;
    width: 10px;
    color: rgba(0, 0, 0, 0.3);
  }
  .cards-price .cards-price-amount .cards-price-validity {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    font-size: 12px;
    text-transform: uppercase;
  }
  .cards-description .cards-description-content-bar {
    display: block;
    list-style: none;
    margin: 40px 0 25px 0;
    padding: 0;
  }
  .cards-description .cards-description-content {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: left;
    margin: 0 0 10px;
  }
  .cards-description .cards-description-content::before {
    content: "♦";
    padding: 0 5px 0 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
  .cards-action .cards-action-button {
    background: transparent;
    border: 2px solid $color;
    border-radius: 30px;
    color: $color;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  .cards-action .cards-action-button:hover {
    background-color: $color;
    box-shadow: 0 2px 4px #4f1990;
    color: #fff;
    font-size: 17px;
  }
}
</style>